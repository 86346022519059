import React from 'react'

import useCmsInstitutional from '../../hooks/useCmsInstitutional'
import { NpmInstitutionalPage, NpmStoreInfo } from '../../local_modules'

import '../../styles/pages/lojas.scss'

const Stores = () => {
  const { storeBanners, storeContent } = useCmsInstitutional()

  return (
    <NpmInstitutionalPage
      title="Nossas Lojas e Serviços"
      banners={storeBanners}
    >
      <div className="stores-container">
        <section className="stores">
          <NpmStoreInfo storeList={storeContent} />
        </section>
      </div>
    </NpmInstitutionalPage>
  )
}

export default Stores
